import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 此处引入 Animate.css 动画库
import 'animate.css'

import App from './App'
import router from './router'

Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$global = global

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
