import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    meta: { title: '主页' },
    component: () => import('@/views/home/indexHome.vue')
  },
  {
    path: '/product/softwareProduct',
    name: 'SoftwareProduct',
    meta: { title: '软件产品' },
    component: () => import('@/views/product/softwareProduct.vue'),
    exact: true
  },
  {
    path: '/product/softwareProduct/videoRetrieval',
    name: 'VideoRetrieval',
    meta: { title: '视频检索' },
    component: () => import('@/views/product/softwareProduct/videoRetrieval.vue'),
    exact: true
  },
  {
    path: '/product/softwareProduct/inspectionPlatform',
    name: 'InspectionPlatform',
    meta: { title: '巡检平台' },
    component: () => import('@/views/product/softwareProduct/inspectionPlatform.vue'),
    exact: true
  },
  {
    path: '/product/softwareProduct/intelligentHospitality',
    name: 'IntelligentHospitality',
    meta: { title: '智能待客' },
    component: () => import('@/views/product/softwareProduct/intelligentHospitality.vue'),
    exact: true
  },
  {
    path: '/product/hardwareProduct',
    name: 'HardwareProduct',
    meta: { title: '硬件产品' },
    component: () => import('@/views/product/hardwareProduct.vue'),
    exact: true
  },
  {
    path: '/product/algorithmModel',
    name: 'AlgorithmModel',
    meta: { title: '算法模型' },
    component: () => import('@/views/product/algorithmModel.vue'),
    exact: true
  },
  {
    path: '/solution',
    name: 'Solution',
    meta: { title: '解决方案' },
    component: () => import('@/views/solution/indexSolution.vue'),
    exact: true
  },
  {
    path: '/openPlatform',
    name: 'OpenPlatform',
    meta: { title: '开放平台' },
    component: () => import('@/views/openPlatform/openPlatform.vue'),
    exact: true
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: '关于我的' },
    component: () => import('@/views/about/indexAbout.vue'),
    exact: true
  }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  linkActiveClass: 'active',
  mode:'history',
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
