<template>
  <div id="app">
    <div class='kf' @mouseenter='enterContent(1)' @mouseleave='enterContent(2)' @click='gotoKf'>
      <span class="uhi-icon-kefu iconfont" :style='iconColor'></span>
    </div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      iconColor: { color: 'black' }
    }
  },
  methods: {
    enterContent(type) {
      if (type === 1) {
        this.iconColor.color = '#005be3'
      }
      if (type === 2) {
        this.iconColor.color = 'black'
      }
    },
    gotoKf() {
      window.open('https://work.weixin.qq.com/kfid/kfcd9277b9662623563', '_blank') 
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
  .kf {
    background-color: #ffffff;
    box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
    width: 50px;
    height: 50px;
    position: fixed;
    top: 85%;
    z-index: 90000;
    left: 95%;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }
  .iconfont{
    color: black;
    font-size: 20px;
    font-weight: bold;
  }
</style>
